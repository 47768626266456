//import 'core-js/stable' // https://github.com/zloirock/core-js#commonjs-api
import '@github/file-attachment-element'
import '@spectrum-web-components/overlay/sync/overlay-trigger.js';
import { provideFluentDesignSystem, allComponents } from '@fluentui/web-components'
// Initialize fluent web-components
provideFluentDesignSystem().register(allComponents)

// Components
export * from './components/index'

// Modules
//export * from './modules/sample-custom-element/index'
// export * as ModernEmailEditor from './modules/modern-email-editor/index'
// export * as ODataViews from './modules/odata-views/index'
// export * as ReviewTimer from './modules/review-timer/index'
